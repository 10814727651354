import { useScreenSize } from '@/themes/media-query'
import { DataGrid, TextBox, Toolbar as DxToolbar } from 'devextreme-react'

import { useCallback, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  MasterDetail,
  FilterPanel,
  DataGridRef,
  Search,
  Pager,
} from 'devextreme-react/data-grid'
import { ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import useTokenRefresh from '@/auth/azure/azureManager'
import TicketBriefDetail from '@/routes/tickets/TicketBriefDetail'
import { Rating, ThinRoundedStar } from '@smastrom/react-rating'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import { Button as TextAreaButton, TextBoxRef } from 'devextreme-react/text-box'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { columnSourceFactory } from '@/routes/utils'
import DateColumn from '@/components/date-column/DateColumn'
import { ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { gridTotalItemsLabel } from '@/constants'

const TicketsHistoricPage = () => {
  const token = useTokenRefresh()
  const refSearch = useRef<TextBoxRef>(null)
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  // const [ticketsHistoricDataSource, setTicketsHistoricDataSource] = useState<ticket[]>([])

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '100vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Tickets')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HistoricTickets.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const cellRenderRating = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value > 0 ? (
      <Rating
        style={{ maxHeight: 20 }}
        value={cellData.value}
        readOnly={true}
        itemStyles={{
          itemShapes: ThinRoundedStar,
          activeFillColor: '#ffb700',
          inactiveFillColor: '#fbf1a9',
        }}
      />
    ) : (
      <></>
    )
  }

  const [currentFilter, setCurrentFilter] = useState<any[]>([['stato/id', StatoTicket.COMPLETATO]])
  const ticketsHistoricDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ticket`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    expand: [
      'sede($expand=azienda($select=nome);$select=nome,azienda)',
      'tipologia_ticket($select=nome)',
      'assegnato($select=fullname)',
      'tipologia_problema($select=nome)',
      'argomento_problema($select=nome)',
      'piattaforma($select=nome)',
      'applicazione($select=nome)',
      'creatore($select=fullname)',
      'eventi($expand=utente($select=fullname), tipologia($select=nome))',
      'note_ticket($expand=utente($select=fullname))',
    ],
    filter: currentFilter,
    requireTotalCount: true,
  }

  return (
    <>
      <h2 className={'content-block'}>Storico Ticket Completati</h2>
      <div className={'content-block'}>
        <DxToolbar>
          <ToolbarItem location="after" locateInMenu="never">
            <TextBox
              width={currentScreenSize.isXSmall ? '250px' : '400px'}
              ref={refSearch}
              placeholder="Search..."
              showClearButton={true}
              onValueChanged={async (e: ValueChangedEvent) => {
                console.log('value', e.value)
                const value: string = e.value.trim()
                if (value) {
                  const filter = []
                  if (value.length > 0) {
                    if (value.at(0) === `"` && value.at(-1) === `"`) {
                      const text: string = value.slice(1, Math.max(0, value.length - 1))
                      console.log('text', text)
                      filter.push([
                        ['soluzione', 'contains', text],
                        'or',
                        ['descrizione', 'contains', text],
                        'or',
                        [new EdmLiteral(`note_ticket/any(nota: contains(nota/testo, '${text}'))`), '=', true],
                        'or',
                      ])
                      setCurrentFilter([[filter], 'and', ['stato/id', StatoTicket.COMPLETATO]])
                    } else {
                      console.log('parole')
                      const words: string[] = e.value.split(' ').filter((k: string) => k !== '')
                      for (const word of words) {
                        filter.push(
                          [
                            ['soluzione', 'contains', word],
                            'or',
                            ['descrizione', 'contains', word],
                            'or',
                            [new EdmLiteral(`note_ticket/any(nota: contains(nota/testo, '${word}'))`), '=', true],
                            'or',
                          ],
                          'or',
                        )
                      }
                      filter.pop()
                      setCurrentFilter([[filter], 'and', ['stato/id', StatoTicket.COMPLETATO]])
                    }
                  } else {
                    setCurrentFilter([['stato/id', StatoTicket.COMPLETATO]])
                  }
                } else {
                  setCurrentFilter([['stato/id', StatoTicket.COMPLETATO]])
                }
              }}
            >
              <TextAreaButton
                name="search"
                location="after"
                options={{
                  icon: 'search',
                  stylingMode: 'text',
                  onClick: () => {
                    refSearch.current?.instance().option('focus', false)
                  },
                }}
              />
              <TextAreaButton name="clear" />
            </TextBox>
          </ToolbarItem>
        </DxToolbar>

        <DataGrid
          id={'tickets-historic-datagrid'}
          height={getGridHeight}
          noDataText="Nessun ticket trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          dataSource={ticketsHistoricDataSource}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/tickets/${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <MasterDetail enabled={true} component={TicketBriefDetail} />,
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={'tickets-historic-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left"></Column>
          <Column
            dataField={'id'}
            visible={false}
            caption="ID"
            width={'auto'}
            dataType="number"
            defaultSortOrder="desc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'id', true)}></HeaderFilter>
          </Column>
          <Column
            dataField={'rating'}
            caption={'RATING'}
            cellRender={cellRenderRating}
            minWidth={70}
            width={70}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          />
          <Column
            dataField={'ded_Dis'}
            caption={'TICKET ID'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.azienda.nome'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ticket = cellData.row.data
              return (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              )
            }}
            caption={'AZIENDA'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 16 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.nome'}
            caption={'SEDE'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          />
          <Column
            dataField={'leg_contatto'}
            caption={'CONTATTO'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'leg_contatto')}>
              <Search enabled={true} searchExpr={'leg_contatto'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'creatore.fullname'}
            caption={'CREATORE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'creatore.fullname')}>
              <Search enabled={true} searchExpr={'creatore.fullname'} />
            </HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'apertura',
            caption: 'INIZIO',
            format: 'dd/MM/yyyy HH:mm',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined,
          })}
          {DateColumn({
            dataField: 'chiusura',
            caption: 'FINE',
            format: 'dd/MM/yyyy HH:mm',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined,
          })}
          <Column
            dataField="assegnato.fullname"
            caption="ASSEGNATO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'assegnato.fullname')}>
              <Search enabled={true} searchExpr={'assegnato.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="tipologia_ticket.nome"
            caption="CATEGORIA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_ticket', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="durata"
            caption="DURATA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'durata', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="piattaforma.nome"
            caption="PIATTAFORMA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'piattaforma_ticket', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="applicazione.nome"
            caption="SOFTWARE QS"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'qs_supporto_applicazione', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="tipologia_problema.nome"
            caption="PROBLEMA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_problema', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="argomento_problema.nome"
            caption="ARGOMENTO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'argomento_problema', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            {!currentScreenSize.isXSmall && <Item name="groupPanel" />}
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default TicketsHistoricPage
